<template>
  <v-row class="home" style="max-width: 1200px;">
    <v-col cols="12">
      <h2>Home</h2>
    </v-col>

    <v-col cols="12" v-if="user.user_role === 'Executive'">
      <executive-summary-orders :data="executiveOrderSummary" :loading="loading" />
    </v-col>

    <v-col cols="12" v-if="user.user_role === 'Executive'">
      <executive-summary-collections :data="executiveCollectionsSummary" :loading="loading" />
    </v-col>

    <v-col cols="12" v-if="user.user_role !== 'Executive'">
      <commissions-summary/>
    </v-col>

    <v-col cols="12">
      <sales-targets/>
    </v-col>

    <v-col cols="12">
      <payment-summary></payment-summary>
    </v-col>
  </v-row>
</template>

<script>
import executiveSummaryOrders from '../components/charts/ExecutiveSummaryOrders.vue';
import executiveSummaryCollections from '../components/charts/ExecutiveSummaryCollections.vue';
import commissionsSummary from '../components/charts/CommissionsSummary.vue';
import paymentSummary from '../components/charts/PaymentSummary.vue';
import salesTargets from '../components/SalesTargets.vue';
import { executiveSummary } from '../api/misc.api';

export default {
  name: 'Home',
  props: {
    msg: String,
  },
  components: {
    paymentSummary,
    commissionsSummary,
    executiveSummaryOrders,
    executiveSummaryCollections,
    salesTargets,
  },
  data() {
    return {
      loading: true,
      executiveOrderSummary: {},
      executiveCollectionsSummary: {}
    };
  },
  computed: {
    user() {
      return this.$store.state.auth.user.user;
    },
  },
  methods: {
    async loadExecutiveData() {
      this.loading = true;
      try {
        const resp = await executiveSummary(false);
        this.data = resp.data;
        this.parseExecutiveOrderSummary(this.data);
        this.parseExecutiveCollectionsSummary(this.data);
      } catch (err) {
        console.error(err);
      } finally {
        this.loading = false;
      }
    },
    LightenDarkenColor(color, percent) {
      let R = parseInt(color.substring(1 ,3), 16);
      let G = parseInt(color.substring(3, 5), 16);
      let B = parseInt(color.substring(5, 7), 16);

      R = parseInt(R * (100 + percent) / 100);
      G = parseInt(G * (100 + percent) / 100);
      B = parseInt(B * (100 + percent) / 100);

      R = (R<255)?R:255;
      G = (G<255)?G:255;
      B = (B<255)?B:255;

      let RR = ((R.toString(16).length==1)?"0"+R.toString(16):R.toString(16));
      let GG = ((G.toString(16).length==1)?"0"+G.toString(16):G.toString(16));
      let BB = ((B.toString(16).length==1)?"0"+B.toString(16):B.toString(16));

      return "#"+RR+GG+BB;
    },
    parseExecutiveOrderSummary(data) {
      const salesReps = data.map((d) => d.sales_rep_name).filter((value, index, self) => self.indexOf(value) === index);
      const colors = ['#3d8ec6', '#FF6E40', '#1ab808'];

      const orderSubtotal = salesReps.map((s) => {
        const setData = this.data.filter((d) => d.sales_rep_name === s).map((d) => d.order_subtotal);

        return {
          type: 'bar',
          label: s,
          data: setData,
          order: 2,
          backgroundColor: colors[salesReps.indexOf(s)],
          parsing: {
            xAxisKey: 'sales_rep_name',
            yAxisKey: 'order_subtotal',
          },
        };
      });

      const numOrders = salesReps.map((s) => {
        const setData = this.data.filter((d) => d.sales_rep_name === s).map((d) => d.num_orders);

        return {
          type: 'line',
          label: s,
          data: setData,
          order: 1,
          backgroundColor: this.LightenDarkenColor(colors[salesReps.indexOf(s)],35),
          borderColor: this.LightenDarkenColor(colors[salesReps.indexOf(s)],35),
          fill: false,
          parsing: {
            xAxisKey: 'sales_rep_name',
            yAxisKey: 'order_subtotal',
          },
          yAxisID: 'num-orders',
        };
      });

      const datasets = [...orderSubtotal, ...numOrders];

      this.executiveOrderSummary = {
        labels: this.data.map((d) => this.$moment.utc(d.date).format('MMM. Do')).filter((value, index, self) => self.indexOf(value) === index),
        datasets,
      };
    },
    parseExecutiveCollectionsSummary(data) {
      const salesReps = data.map((d) => d.sales_rep_name).filter((value, index, self) => self.indexOf(value) === index);
      const colors = ['#3d8ec6', '#FF6E40', '#1ab808'];
      const orderSubtotal = salesReps.map((s) => {
        const setData = this.data.filter((d) => d.sales_rep_name === s).map((d) => d.collected_subtotal);

        return {
          type: 'bar',
          label: s,
          data: setData,
          order: 2,
          backgroundColor: colors[salesReps.indexOf(s)],
          parsing: {
            xAxisKey: 'sales_rep_name',
            yAxisKey: 'collected_subtotal',
          },
        };
      });

      const numOrders = salesReps.map((s) => {
        const setData = this.data.filter((d) => d.sales_rep_name === s).map((d) => d.num_collections);

        return {
          type: 'line',
          label: s,
          data: setData,
          order: 1,
          backgroundColor: this.LightenDarkenColor(colors[salesReps.indexOf(s)],35),
          borderColor: this.LightenDarkenColor(colors[salesReps.indexOf(s)],35),
          fill: false,
          parsing: {
            xAxisKey: 'sales_rep_name',
            yAxisKey: 'collected_subtotal',
          },
          yAxisID: 'num-collections',
        };
      });

      const datasets = [...orderSubtotal, ...numOrders];

      this.executiveCollectionsSummary = {
        labels: this.data.map((d) => this.$moment.utc(d.date).format('MMM. Do')).filter((value, index, self) => self.indexOf(value) === index),
        datasets,
      };
    },
  },
  mounted() {
    this.loadExecutiveData();
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
