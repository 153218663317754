<template>
  <v-row>
    <v-col cols="12">
      <v-card>
        <v-card-text v-if="loading">
          <loading :loading="loading"></loading>
        </v-card-text>
        <v-card-text v-else>
          <div class="text-center">
            <strong>Executive Summary - Orders (10 Days)</strong>
          </div>
          <line-chart
            v-if="!loading"
            :chartdata="data"
            :options="options"
            :height="null"
            :width="null"
          ></line-chart>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>

import LineChart from './LineChart.vue';

export default {
  name: 'ExecutiveSummaryChart',
  components: {
    LineChart,
  },
  props: {
    loading: {
      type: Boolean, 
      default: false,
    },
    data: {
      type: Object,
      default: () => {},
    }
  },
  data() {
    return {
      options: {
        maintainAspectRatio: true,
        aspectRatio: 2.0,
        responsive: true,
        tooltips: {
          mode: 'index',
          intersect: false,
          callbacks: {
            label(tooltipItem, d) {
              const dataset = d.datasets[tooltipItem.datasetIndex];

              if (dataset.yAxisID === 'num-orders') {
                return `${Number(tooltipItem.yLabel).toFixed(0).replace(/./g, (c, i, a) => (i > 0 && c !== '.' && (a.length - i) % 3 === 0 ? `,${c}` : c))}`;
              }

              return `$${Number(tooltipItem.yLabel).toFixed(0).replace(/./g, (c, i, a) => (i > 0 && c !== '.' && (a.length - i) % 3 === 0 ? `,${c}` : c))}`;
            },
          },
        },
        scales: {
          xAxes: [{
            stacked: true,
            scaleLabel: {
              display: true,
              labelString: 'Date',
            },
          }],
          yAxes: [{
            stacked: true,
            scaleLabel: {
              display: true,
              labelString: 'Order Subtotal',
            },
            ticks: {
              min: 0,
              // Include a dollar sign in the ticks
              callback(value) {
                return `$${value}`;
              },
            },
          },
          {
            id: 'num-orders',
            position: 'right',
            scaleLabel: {
              display: true,
              labelString: '# Orders',
            },
            ticks: {
              min: 0,
              // Include a dollar sign in the ticks
              callback(value) {
                return `${value}`;
              },
            },
          }],
        },
        legend: {
          position: 'top',
          align: 'center',
          labels: {
            filter: function(item, chart) {
              const dataset = chart.datasets[item.datasetIndex];

              // Logic to remove a particular legend item goes here
              return dataset.type === 'bar'
            }
          }
        },
      },
    };
  },
  methods: {
  },
  mounted() {
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
